import {FC, MouseEvent, useCallback} from 'react';
import {TableItemPaymentType} from "../../../types/components/table-item-payment.type.ts";

import moment from 'moment-timezone'
import {formatCurrency, getHumanDescCurrency, getNetworkIcon} from "../../../utils/currency.util.ts";
import {openInNewTab, statusColor, toShort2} from "../../../utils/common.util.ts";
import {AppDispatch} from "../../../../store";
import {useDispatch} from "react-redux";
import {openPaymentPanel} from "../../../../store/payment.store.ts";
import useTimezone from "../../../hooks/timezone.hook.ts";
import AddressComponent from "../../general/address.component.tsx";
import {Position} from "../../../enums/position.enum.ts";
import {OperationError} from "../../../enums/operation-error.enum.ts";


const TableItemPayment: FC<TableItemPaymentType> = ({item}) => {
    const dispatcher: AppDispatch = useDispatch();
    const timezone = useTimezone()

    const paymentPanelHandler = useCallback(() => {
        dispatcher(openPaymentPanel(item.id));
    }, [dispatcher, item.id]);

    const linkHandler = useCallback((e: MouseEvent<HTMLDivElement>, url?: string): void => {
        e.stopPropagation();
        if (url) {
            openInNewTab(url);
        }
    }, []);

    return (
        <tr onClick={paymentPanelHandler} className="cursor-pointer hover:bg-gray-100 select-none">
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div onClick={(e) => linkHandler(e, `${import.meta.env.VITE_APP_URL}/i/${item.internalId}`)}
                     className={`w-fit block font-medium text-indigo-500 hover:cursor-pointer hover:underline`}>{toShort2(item.orderId, 6, 0)}</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 pl-3 whitespace-nowrap">
                <div className={`font-medium`}>{moment(item.createdAt).tz(timezone).format('DD/MM/YYYY HH:mm')}</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className={`font-medium`}>
                    <img className="w-8 h-8 rounded-full" src={getNetworkIcon(item.walletType)} alt="BNB Icon"/>
                </div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div
                    className={`font-medium`}>{formatCurrency(item.amount) + ` ${getHumanDescCurrency(item.currency)}`}</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className={`font-medium`}>{formatCurrency(item.paymentFee || 0)}</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap max-w-[100px]">
                <div className={`font-medium w-fit`}>{toShort2(item.description, 14, 0)}</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  text-right">
                <div className="block w-fit">
                    <AddressComponent
                        onClick={(e) => linkHandler(e, item.clientTransactionLink)}
                        address={item.paymentAddress || ''}
                        showCopyMessageTimeout={500}
                        position={Position.RIGHT}
                    />
                </div>
            </td>
            <td className={`px-2 flex justify-center items-center first:pl-5 last:pr-5 py-3 whitespace-nowrap `}>
                <div className="relative w-full h-full">
                    <div
                        className={`flex items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 font-medium rounded-full px-2.5 py-0.5 ${statusColor(item.status)}`}>
                        {(item.error && item.error !== OperationError.NONE) && <span className="relative flex h-2 w-2 mr-2">
                          <span
                              className="animate-ping absolute inline-flex h-full w-full rounded-full bg-rose-400 opacity-75"></span>
                          <span className="relative inline-flex rounded-full h-2 w-2 bg-rose-500"></span>
                        </span>}
                        {item.status.toLowerCase()}
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default TableItemPayment;


